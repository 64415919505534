import { createBrowserRouter } from 'react-router-dom';
import { Layout } from '@/components/layouts/layout';
import { Connections } from '@/pages/connections';
import { Connection } from '@/pages/connection';
import { Scenarios } from '@/pages/scenarios';
import { Analytics } from '@/pages/analytics';
import { CreateConnection } from '@/pages/create-connection';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Connections />,
      },
      {
        path: '/connections/:operatorId/:connectionId',
        element: <Connection />,
      },
      {
        path: '/connection/:operatorId/create',
        element: <CreateConnection />,
      },
      {
        path: '/scenarios',
        element: <Scenarios />,
      },
      {
        path: '/analytics',
        element: <Analytics />,
      },
      {
        path: '*',
        element: <>404</>,
      },
    ],
  },
]);
