import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/icons';

export default function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <Button
      variant="default"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      <Icons.signOut className="mr-2 h-4 w-4" />
      Sign out
    </Button>
  );
}
