import { useCustomMutation, useCustomQuery } from '@/hooks/api';
import { useParams } from 'react-router-dom';
import { createConnection, getConnectionSchema } from '@/queries';
import { ConnectionForm } from '@/components/connection-form';
import { useNavigate } from 'react-router-dom';
import { QueryKeys } from '@/queries/query-keys';
import { inputTypes } from '@/config';
import { SkeletonForm } from '@/components/skeleton-form';

const customFields = [
  {
    type: inputTypes.TypeString,
    name: 'title',
    value: '',
    metadata: '',
  },
];

export function CreateConnection() {
  const { operatorId } = useParams();

  const navigate = useNavigate();

  const { data, isFetching } = useCustomQuery({
    queryKey: [QueryKeys.ConnectionSchema, operatorId],
    queryFn: getConnectionSchema,
  });

  const { mutate, isPending } = useCustomMutation({
    mutationFn: createConnection,
    onSuccess: () => navigate('/'),
  });

  const handleSubmit = (data: Record<string, unknown>) => {
    const { title, ...parameters } = data;

    mutate({
      title,
      connectionData: {
        parameters,
      },
      operatorName: operatorId,
    });
  };

  if (isFetching) {
    return (
      <section className="py-4">
        <SkeletonForm />
      </section>
    );
  }

  return (
    <section className="py-4">
      <ConnectionForm
        title="Create connection"
        fieldsSchema={data}
        customFields={customFields}
        loading={isPending}
        onSubmit={handleSubmit}
      />
    </section>
  );
}
