import { useForm } from 'react-hook-form';
import { TextInput } from '@/components/text-input';
import { CheckboxInput } from '@/components/checkbox-input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import { Fragment } from 'react/jsx-runtime';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { mapConnectionDefaultValues } from '@/lib/utils';
import { ConnectionSchema } from '@/lib/types';
import { inputTypes } from '@/config';

interface ConnectionFormProps {
  isEdit?: boolean;
  title: string;
  fieldsSchema: ConnectionSchema[];
  customFields?: ConnectionSchema[];
  loading?: boolean;
  onRemove?: () => void;
  onSubmit: (v: any) => void;
}

export function ConnectionForm(props: ConnectionFormProps) {
  const {
    isEdit,
    title,
    fieldsSchema,
    customFields = [],
    loading,
    onRemove,
    onSubmit,
  } = props;

  const allInputs = [...customFields, ...fieldsSchema];

  const form = useForm({
    defaultValues: mapConnectionDefaultValues(allInputs),
  });

  const renderField = (field: ConnectionSchema) => {
    switch (field.type) {
      case inputTypes.TypeString:
        return <TextInput {...field} />;
      case inputTypes.TypeBoolean:
        return <CheckboxInput {...field} />;
      default:
        return null;
    }
  };

  return (
    <Card className="w-full lg:max-w-[50%]">
      <CardHeader className="p-6">
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
            <CardContent className="grid gap-y-6 p-0">
              {allInputs.map((field) => {
                return (
                  <Fragment key={field.name}>{renderField(field)}</Fragment>
                );
              })}
            </CardContent>
            <div className="flex justify-between">
              <Button variant="secondary" asChild disabled={loading}>
                <Link to="/">Cancel</Link>
              </Button>
              <div className="space-x-3">
                {isEdit && (
                  <Button
                    type="button"
                    variant="link"
                    disabled={loading}
                    onClick={onRemove}
                  >
                    Delete
                  </Button>
                )}
                <Button type="submit" disabled={loading}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
