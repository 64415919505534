import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { Icons } from './icons';

export default function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button onClick={() => loginWithRedirect()}>
      <Icons.signIn className="mr-2 h-4 w-4" />
      Sign in
    </Button>
  );
}
